@import url(https://fonts.googleapis.com/css?family=Patrick+Hand|Kalam);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@font-face {
  font-family: 'Castledown-regular';
  src: url(/fnt/castledown-regular.otf); /* IE9 Compat Modes */
  src: url(/fnt/castledown-regular.otf) format('embedded-opentype'), /* IE6-IE8 */
       url(/fnt/castledown-regular.otf) format('woff2'), /* Super Modern Browsers */
       url(/fnt/castledown-regular.otf) format('woff'), /* Pretty Modern Browsers */
       url(/fnt/castledown-regular.otf)  format('opentype'), /* Safari, Android, iOS */
       url(/fnt/castledown-regular.otf) format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Castledown-bold';
  src: url(/fnt/castledown-bold.otf); /* IE9 Compat Modes */
  src: url(/fnt/castledown-bold.otf) format('embedded-opentype'), /* IE6-IE8 */
       url(/fnt/castledown-bold.otf) format('woff2'), /* Super Modern Browsers */
       url(/fnt/castledown-bold.otf) format('woff'), /* Pretty Modern Browsers */
       url(/fnt/castledown-bold.otf)  format('opentype'), /* Safari, Android, iOS */
       url(/fnt/castledown-bold.otf) format('svg'); /* Legacy iOS */
}

html, body {
  height: 100%;
}

html {
  font-size: 14px;
  --yoto-red: #F85E41;
  --yoto-green: #003C1F;
}


body {
  margin: 0;
  padding: 0;
  font-family: Castledown-Regular, Roboto, sans-serif;
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  color: #003c1f;
}

h1 {
  font-family: Castledown-Bold, sans-serif;
  color: #F85E41;
  font-weight: bold;
  font-size: 44px;
  margin-bottom: 20px;
}

h5 {
  font-family: Castledown-Bold, sans-serif;
  color: #F85E41;
  font-weight: bold;
  font-size: 20px;
  padding: 10px 0 4px 0;

}

.MuiPaper-root{
  padding-left: 10px;
}

.cardLibSpacer {
  width: 100%;
  height: 100px;
}


#root {
  height: 100%;
}

.message {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  font-weight: bold;
  line-height: 34px;
  color: #003C1F !important;
  padding: 12px !important;
  font-size: 19px;
  z-index: 99;
}


.message.message-error { background-color: red; }

.content {
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}


/**device config*/
.config-list.config-list{
  margin-bottom:22px;
  position: relative;
  padding-bottom:0px;
  padding-top:0px;
  
}
.config-list li:last-child{
    border-radius: 0px 0px 8px 8px;
    padding-bottom:20px;
}


.config-list li{
  margin: 0 16px;
  padding-left: 20px;
  padding-right: 20px;
}
.config-list:before{
  content: '';
  background-color: #fafafa;
  position: absolute;
  top: 40px;
  bottom: 0px;
  left: 16px;
  right:16px;
  border-radius: 8px;
  box-shadow: 0 3px 10px 1px rgba(103,29,15,0.09);
  
}

.config-list .has-border{
  border-radius: 8px 8px 0px 0px;
  padding-top:20px;
}

.config-subheading.config-subheading{
  text-transform: uppercase;
  padding-left:0px;
  padding-right:0px;
  color: #003c1f;
  font-size: 16px;
  line-height: 40px;
  font-family: 'Castledown-bold'
}

.config-subheading-span.config-subheading-span {
  text-transform: lowercase;
  color: #003c1f;
  font-size: 12px;
  font-family: 'Castledown-bold'
}


.config-items.config-items{
  background-color: #fafafa;
  width: auto;
}

.config-list .MuiListItemSecondaryAction-root{
  right:28px;
  max-width: 37vw;
}

.config-value .Mui-checked{
  padding-right: 0px;
}

.config-value .PrivateSwitchBase-root-177{
  padding-right: 0px;
  padding-left: 16px;
}

.config-value .MuiSwitch-root{
  padding-right: 2px;
  padding-left: 18px;
}



.config-icon {
  min-width: 36px !important;
}

.config-key {
  padding-right: 20vw;
}

.config-key-long {
  padding-right: 20px;
}

.config-key sup,
.config-key-long sup {
  font-size: 0.5em;
  padding-left: 0.5em;
}


.config-value {
  max-width: 40vw;
}

.circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 4px 4px 2px 4px;
  border: 1px solid #F85E41;
  color: #000;
  text-align: center;
  font-size: 16px;
  margin: 0 10px 0 0;
}

.circle-full {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  padding: 4px 4px 2px 4px;
  background: #F85E41;
  border: 1px solid #F85E41;
  color: #F85E41;
  text-align: center;
  font-size: 16px;
  margin: 0 10px 0 0;
}

.circle-text {
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
  color: #F85E41;
}

.circle-full-text {
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    color: #fff;
}

.rhbEntryTitle {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rhbEntryList {
  margin-bottom:22px;
  position: relative;
  padding-bottom:0px;
  padding-top:0px;
}

.rhbEntryListItem {
  background-color: #fafafa;
  width: auto;
}

.content {
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.btn-foot-bar {
  width: 100%;
  height: 80px;
  position: fixed;
  bottom: 0;
  padding: 4px 25px;
  background: #FBF9F9;
  z-index: 2;
}
